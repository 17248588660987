import { Stack } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { ReactNode } from "react";

import css from "../custom_value_preview.module.scss";

type DataEntryProps = {
  label: string;
  value: ReactNode;
};

export const DataEntry = ({ label, value }: DataEntryProps) => (
  <Stack className={css.halfWidth}>
    <Typography variant="labelRegular" noMargin>
      {label}
    </Typography>
    <Typography variant="bodyBold" noMargin>
      {value}
    </Typography>
  </Stack>
);
