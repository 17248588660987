import { orderBy, partition, take } from "lodash";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { Button } from "PFComponents/button";
import { SkillPill } from "PFComponents/skill_pill";
import { Divider } from "PFCore/components/divider";
import { isRankable } from "PFCore/helpers/custom_type";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { ActivityPureCustomValue, CustomValueScope, Id, MatchCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./skills_list.module.scss";
import { useSkillsVisibility } from "./use_skills_visibility";

type SkillsListProps = {
  customFieldName: string;
  skills: (MatchCustomValue | ActivityPureCustomValue)[];
  profileId: Id;
  limit?: number;
  skillTruncateLength?: number;
  dark?: boolean;
  experienceTooltipDisabled?: boolean;
  canEdit?: boolean;
  onMoreLessButtonClick?: VoidFunction;
  onMissingSkillClick?: (id: Id) => void;
  isBlindModeOn?: boolean;
};

const SkillsDivider = () => (
  <Divider type="vertical" color="paletteNeutral1" theme={{ divider: css.divider }} />
);

// TODO: [PROF-7377] Change naming from Skills -> CustomValues
export const SkillsList = ({
  customFieldName,
  skills,
  profileId,
  limit = 8,
  skillTruncateLength,
  dark = false,
  experienceTooltipDisabled = false,
  canEdit = false,
  onMoreLessButtonClick,
  onMissingSkillClick,
  isBlindModeOn = false
}: SkillsListProps): JSX.Element => {
  const { t } = useTranslation("core");
  const { customTypes } = useCustomTypes();
  const { showPreview } = useCustomValuePreviewActions();

  const [availableSkills, missingSkills] = partition(
    skills,
    ({ experience }: MatchCustomValue) => experience !== undefined
  ) as [MatchCustomValue[], ActivityPureCustomValue[]];

  const sortedAvailableSkills = orderBy(
    availableSkills,
    [({ top }) => Boolean(top), "value"],
    ["desc", "asc"]
  );

  const sortedMissingSkills = orderBy(missingSkills, "value");

  const {
    visibleAvailableSkillsCount,
    visibleMissingSkillsCount,
    showAll,
    displayShowMoreButton,
    toggleShowAll
  } = useSkillsVisibility({
    availableSkillsCount: sortedAvailableSkills.length,
    missingSkillsCount: sortedMissingSkills.length,
    limit
  });

  const handleMoreLessButtonClick = () => {
    toggleShowAll();
    onMoreLessButtonClick?.();
  };

  const customType = customTypes.find(({ name }) => name === customFieldName);
  const isCustomTypeRankable = Boolean(customType && isRankable(customType));

  return (
    <>
      <div className={css.skills}>
        {take(sortedAvailableSkills, visibleAvailableSkillsCount).map((skill, index) => {
          const isNotLastSkill = index < visibleAvailableSkillsCount - 1;
          const needToSeparateMissingSkillsList =
            index === visibleAvailableSkillsCount - 1 && visibleMissingSkillsCount > 0;

          return (
            <span className={css.skillWithDivider} key={skill.id}>
              <SkillPill
                experience={isCustomTypeRankable ? skill.experience : undefined}
                requiredExperience={isCustomTypeRankable ? skill.required_experience : undefined}
                name={String(skill.value)}
                isCore={skill.top}
                isDevelopmental={skill.developmental}
                isVerified={skill.assessed}
                isVerifiedCredly={skill.assessment_source === "credly"}
                isVerifiedOthers={skill.em_verified}
                truncateLength={skillTruncateLength}
                theme={dark ? "dark" : "light"}
                showTooltip={isCustomTypeRankable && !experienceTooltipDisabled}
                customIconName={!isCustomTypeRankable ? "check" : undefined}
                onClick={() => {
                  showPreview({
                    scope: CustomValueScope.Profile,
                    customTypeId: customType!.id,
                    customValueId: skill.id,
                    profileId,
                    canEdit,
                    isBlindModeOn
                  });
                }}
              />
              {(isNotLastSkill || needToSeparateMissingSkillsList) && <SkillsDivider />}
            </span>
          );
        })}
        {take(sortedMissingSkills, visibleMissingSkillsCount).map((skill, index) => {
          const isNotLastSkill = index < visibleMissingSkillsCount - 1;

          return (
            <span className={css.skillWithDivider} key={skill.id}>
              <SkillPill
                requiredExperience={isCustomTypeRankable ? skill.required_experience : undefined}
                name={String(skill.text)}
                truncateLength={skillTruncateLength}
                theme={dark ? "dark" : "light"}
                showTooltip={isCustomTypeRankable && !experienceTooltipDisabled}
                customIconName={!isCustomTypeRankable ? "cross" : undefined}
                onClick={onMissingSkillClick ? () => onMissingSkillClick?.(skill.id) : undefined}
              />
              {isNotLastSkill && <SkillsDivider />}
            </span>
          );
        })}
        {displayShowMoreButton && (
          <Button
            kind="text"
            onClick={(event) => {
              event.stopPropagation();
              handleMoreLessButtonClick();
            }}
            darkBackground={dark}
          >
            {showAll ? t("viewLess") : t("viewMore")}
          </Button>
        )}
      </div>
    </>
  );
};
